import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const images = [
    '/img/carousel1.png',
    '/img/carousel2.png',
    '/img/carousel3.png',
    '/img/carousel4.png',
    '/img/carousel5.png',
    '/img/carousel6.png',
    '/img/carousel7.png'
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => 
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.carousel}>
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index + 1}`}
          className={`${styles.slide} ${index === currentSlide ? styles.active : ''}`}
        />
      ))}
    </div>
  );
};

export default Carousel; 